import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: 'redirect-calendar',
    template: '',
    encapsulation: ViewEncapsulation.None
})
export class RedirectCalendarComponent implements OnInit {
    constructor(
        private router: Router
    ){}

    get companyId() {
      return localStorage.getItem("currentCompanyId");
    }

    ngOnInit(): void {
        this.router.navigate([`app/${this.companyId}/calendar`])
    }
}