import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AvatarService {

  private _url: string;
  private _token: string;  
  public avatarUpdated = new EventEmitter();

  constructor(
    private _http: HttpClient
  ) { 
    this._url = environment.apiUrl;
    this._token = `Bearer ${localStorage.getItem('currentToken')}`;    
  }

  sendFileToServer(formData: any): Observable<any> {    
    return this._http.post<any>(`${this._url}/file/upload/avatar`, formData, {
      headers:{                        
        "Authorization": this._token
      }
    });    
  }

  updateAvatarUrl(body): Observable<any> {    
  
      return this._http.put<any>(`${this._url}/user/${body.id}`, body, {
      headers:{
        "Content-Type": "application/json",
        "Authorization": this._token
      }
    });

  }
  public emitAvatarUpdate(params:any):void {
    
    this.avatarUpdated.emit(params); 
    }  
}
