<div class="modal-content">
  <div class="modal-body">
    <div class="modal-header-left">
      <button type="button" class="btn btn-back" text-white (click)="goBack()">
        <span class="feather-icon" data-feather="arrow-left"></span>
        <span>Voltar</span>
      </button>
    </div>
    <div class="modal-title">
      <h1>Criar novo link de cadastro</h1>
    </div>
    <div class="user-links" *ngIf="step === stepsEnum.CREATE">
      <h3>Personalize seu link</h3>
      <div class="input-container">
        <div class="url-input">
          <span>Nome</span>
        </div>
      </div>
      <div class="input-container">
        <div class="url-input">
          <span>E-mail</span>
        </div>
      </div>
      <div class="input-container">
        <div class="url-input">
          <span>Número do Celular</span>
        </div>
      </div>
      <ng-container *ngFor="let field of customFields; let i = index">
        <div class="input-container">
          <div class="dynamic-input">
            <span>{{ customFields[i] }}</span>
          </div>
          <div class="icons-input">
            <button (click)="removeField(i)">
              <span class="icon" data-feather="minus"></span>
            </button>
          </div>
        </div>
      </ng-container>
      <div class="input-container" *ngIf="showSelect">
        <select
          class="dynamic-input"
          name="custom-fields"
          #customField
          (change)="onSelected(customField.value)"
        >
          <option value="" selected>Selecione Campos Personalizados</option>
          <ng-container *ngFor="let field of customFieldsSelect; let i = index">
            <option
              *ngIf="!customFields.includes(field.name)"
              [value]="field.name"
            >
              {{ field.name }}
            </option>
          </ng-container>
        </select>
        <div class="icons-input">
          <button (click)="addField()">
            <span class="icon" data-feather="plus"></span>
          </button>
        </div>
      </div>
    </div>

    <div class="user-links" *ngIf="step === stepsEnum.CONFIRM">
      <h3>Confirmação dos dados</h3>
      <div class="input-container">
        <div class="url-input">Nome</div>
      </div>
      <div class="input-container">
        <div class="url-input">E-mail</div>
      </div>
      <div class="input-container">
        <div class="url-input">Número do Celular</div>
      </div>
      <ng-container *ngFor="let field of customFields; let i = index">
        <div class="input-container">
          <div class="dynamic-input">
            <span>{{ customFields[i] }}</span>
          </div>
        </div>
      </ng-container>
      <div class="input-container">
        <label for="token">Campo obrigatório</label>
        <input
          id="token"
          type="text"
          class="url-input"
          placeholder="Nome do Link"
          [(ngModel)]="token"
        />
      </div>
    </div>

    <div class="action-content" [ngStyle]="{ 'padding-right': '26%' }">
      <button
        *ngIf="step === stepsEnum.CREATE"
        type="button"
        class="btn btn-primary btn-create"
        (click)="sendCreateLink()"
      >
        Confirmar
      </button>
      <button
        *ngIf="step === stepsEnum.CONFIRM"
        type="button"
        class="btn btn-primary btn-create"
        (click)="sendConfirm()"
      >
        Gerar Link
      </button>
    </div>
  </div>
</div>
