export const locale = {
  lang: 'en',
  data: {
    MENU: {
      DASHBOARD: {
        COLLAPSIBLE: 'Dashboards',
      },
      CUSTOMER: 'Customer',
      BUSINESS: 'Funnel',
      REPORTS: 'Reports',
      CALENDAR: 'Calendar'
    }
  }
};
