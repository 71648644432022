import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileFormService {

  private _url: string;
  private _token: string;
  public onProfileUpdate = new EventEmitter();  
  
  constructor(
    private _http: HttpClient
  ) { 
    this._url = environment.apiUrl;
    this._token = `Bearer ${localStorage.getItem('currentToken')}`;    
  }

  updateUserData(id: string, updatedData: any): Observable<any> {    
    return this._http.put<any>(`${this._url}/user/${id}`, updatedData, {
      headers:{
        "Content-Type": "application/json",
        "Authorization": this._token
      }
    });    
  }

  public emitProfileUpdate(params: any): void {
    this.onProfileUpdate.emit(params);
  }
}