import { CoreMenu } from '@core/types';

export const menuSeller: CoreMenu[] = [
  {
    id: 'apps',
    type: 'section',
    title: 'Menu',
    icon: 'package',
    children: [      
      { 
        id: 'customer',
        title: 'Clientes',
        type: 'item',
        icon: 'users',
        url: 'app/:company_id/customer'
      },
      { 
        id: 'funnels',
        title: 'Funil',
        type: 'item',
        icon: 'filter',
        url: 'app/:company_id/funnels'
      },
      { 
        id: 'chat',
        title: 'Chat',
        type: 'item',
        icon: 'message-square',
        url: 'app/:company_id/chat',
        disabled: true,
        badge: {
          title: 'Lock',
          classes: 'badge-light-warning badge-pill'
        }
      },      
      { 
        id: 'calendar',
        title: 'Calendário',
        type: 'item',
        icon: 'calendar',
        url: 'app/:company_id/calendar'
      }      
    ]
  }
];