<!-- Footer -->
<p class="clearfix mb-0">
  <span class="float-md-left d-block d-md-inline-block mt-25">
    COPYRIGHT &copy; {{ year }}
    <a class="ml-25" href="https://wa.me/5571996938199?text=Oi,%20%C3%A9%20da%20VibeCem?" target="_blank" style="color:#FFCD02;" >VibeCRM</a>
    <span class="d-none d-sm-inline-block">, All rights Reserved</span>
  </span>
</p>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>
