import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { customFieldsSelect } from "../utils";
import { stepsEnum } from "../enum";
import { CustomerService } from "app/views/customer/customer.service";
import { CadastroLinkModalComponent } from "../cadastro-link-modal.component";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "create-new-link-modal",
  templateUrl: "./create-new-link-modal.component.html",
  styleUrls: ["../cadastro-link-modal.component.scss"],
})
export class CreateNewLinkModalComponent {
  customFields = [];
  customField: string = null;
  step: stepsEnum.CREATE | stepsEnum.CONFIRM = stepsEnum.CREATE;
  stepsEnum = stepsEnum;
  customFieldsSelect = customFieldsSelect;
  token: string;

  constructor(
    public activeModal: NgbActiveModal,
    private apiServiceCustomer: CustomerService,
    public modalService: NgbModal,
    private _toastr: ToastrService
  ) {}

  get companyId(): string {
    const companyId = localStorage.getItem("currentCompanyId");
    return companyId ? companyId : "";
  }

  goBack(): void {
    if (this.step === stepsEnum.CONFIRM) {
      this.step = stepsEnum.CREATE;
      return;
    }

    this.openModalListLinks();
  }

  openModalListLinks(): void {
    this.modalService.open(CadastroLinkModalComponent, {
      animation: false,
    });
    this.activeModal.dismiss();
  }

  onSelected(value: string): void {
    this.customField = value;
  }

  sendCreateLink(): void {
    this.step = stepsEnum.CONFIRM;
  }

  sendConfirm(): void {
    if (!this.token) return;

    const fieldsAdd = {};
    this.customFields
      .map((fieldToKey) => fieldToKey.toLowerCase())
      .forEach((key) => {
        fieldsAdd[key] = true;
      });

    const body = {
      json: {
        email: true,
        name: true,
        phone: true,
        ...fieldsAdd,
      },
      company_id: this.companyId,
      token: this.token,
    };

    this.apiServiceCustomer.createLink(body).subscribe({
      next: () => {
        this._toastr.success("Link criado com sucesso!");
        this.openModalListLinks();
      },
      error: (error) => {
        throw new Error(error.message);
      },
    });
  }

  addField(): void {
    if (!this.customField) return;
    this.customFields.push(this.customField);
    this.customField = null;
  }

  removeField(index: number): void {
    this.customFields.splice(index, 1);
  }

  get showSelect(): boolean {
    return !(this.customFields.length === customFieldsSelect.length);
  }
}
