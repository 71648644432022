import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CreateNewLinkModalComponent } from "./create-new-link-modal/create-new-link-modal.component";
import { EditLinkModalComponent } from "./edit-link-modal/edit-link-modal.component";
import { DeleteLinkModalComponent } from "./delete-link-modal/delete-link-modal.component";
import { CustomerService } from "../../customer.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "cadastro-link-modal",
  templateUrl: "./cadastro-link-modal.component.html",
  styleUrls: ["./cadastro-link-modal.component.scss"],
})
export class CadastroLinkModalComponent implements OnInit {
  generatedLinks: any[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private apiCustomerService: CustomerService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.getAllLinks();
  }

  get countAvailableLinks(): number {
    return 3 - this.generatedLinks.length;
  }

  get companyId(): string {
    const companyId = localStorage.getItem("currentCompanyId");
    return companyId ? companyId : "";
  }

  dismiss() {
    this.activeModal.dismiss({
      animation: false,
    });
  }

  openModalCreateLink(): void {
    this.modalService.open(CreateNewLinkModalComponent, {
      animation: false,
    });
    this.activeModal.close();
  }

  openModalEdit(idLink: string, token: string): void {
    const modalRef = this.modalService.open(EditLinkModalComponent, {
      animation: false,
    });
    modalRef.componentInstance.idLink = idLink;
    modalRef.componentInstance.token = token;
    this.activeModal.close();
  }

  openModalDeleteLink(idLink: string): void {
    const modalRef = this.modalService.open(DeleteLinkModalComponent, {
      animation: false,
    });
    modalRef.componentInstance.idLink = idLink;
    this.activeModal.close();
  }

  copyText(link: any): void {
    try {
      const text =
        window.location.protocol +
        "//" +
        window.location.host +
        "/register-client-link/" + this.companyId + "/" + link.id;
      navigator.clipboard.writeText(text);
      this.toastr.success("Copiado com sucesso!");
    } catch (err) {
      this.toastr.error("Ocorreu um erro ao copiar");
    }
  }

  getAllLinks(): void {
    this.apiCustomerService.getAllLinks(this.companyId).subscribe({
      next: (res) => {
        this.generatedLinks = res.body;
      },
      error: (error) => {
        throw new Error(error.message);
      },
    });
  }
}
