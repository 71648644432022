import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "phoneFormat",
})
export class PhoneFormatPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;

    value = value.replace(/\D/g, "");

    if (value.length === 13) {
      return `+${value.slice(0, 2)} (${value.slice(2, 4)}) ${value.slice(
        4,
        9
      )}-${value.slice(9)}`;
    }

    if (value.length === 12) {
      return `+${value.slice(0, 2)} (${value.slice(2, 4)}) ${value.slice(
        4,
        8
      )}-${value.slice(8)}`;
    }

    if (value.length === 11) {
      return `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7)}`;
    }

    // Verifica se o número possui 10 dígitos (DDD + Número)
    if (value.length === 10) {
      return `(${value.slice(0, 2)}) ${value.slice(2, 6)}-${value.slice(6)}`;
    }

    // Retorna o número não formatado se não tiver o comprimento esperado
    return value;
  }
}
