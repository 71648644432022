import { Component, Input } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { stepsEnum } from "../enum";
import { CustomerService } from "app/views/customer/customer.service";
import { CadastroLinkModalComponent } from "../cadastro-link-modal.component";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "delete-link-modal",
  templateUrl: "./delete-link-modal.component.html",
  styleUrls: ["../cadastro-link-modal.component.scss"],
})
export class DeleteLinkModalComponent {
  step: stepsEnum.DELETE | stepsEnum.SUCCESS = stepsEnum.DELETE;
  stepsEnum = stepsEnum;
  @Input() idLink: string;
  constructor(
    public activeModal: NgbActiveModal,
    private apiCustomerService: CustomerService,
    public modalService: NgbModal,
    private _toastr: ToastrService
  ) {}

  goBack(): void {
    this.openModalListLinks();
  }

  openModalListLinks(): void {
    this.modalService.open(CadastroLinkModalComponent, {
      animation: false,
    });
    this.activeModal.dismiss();
  }

  get companyId(): string {
    const companyId = localStorage.getItem("currentCompanyId");
    return companyId ? companyId : "";
  }

  deleteLink() {
    this.apiCustomerService.deleteLink(this.idLink).subscribe({
      next: () => {
        this.step = stepsEnum.SUCCESS;
        this._toastr.success("Deletado com sucesso!");
      },
      error: (error) => {
        throw new Error(error.message);
      },
    });
  }
}
