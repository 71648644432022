import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  private _url: string;
  private _token: string;
  public onCloseModal = new EventEmitter<any>();

  constructor(private _http: HttpClient) {
    this._url = environment.apiUrl;
    this._token = `Bearer ${localStorage.getItem("currentToken")}`;
  }
  closeModal() {
    this.onCloseModal.emit();
  }

  getCustomersByCompany(params): Observable<any> {
    return this._http.get<any>(
      `${this._url}/customer/list/${params.company_id}?page=${params.page}&limit=${params.limit}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: this._token,
        },
      }
    );
  }

  createCustomer(data: any): Observable<any> {
    return this._http.post<any>(`${this._url}/customer`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: this._token,
      },
    });
  }

  deleteCustomer(id: string): Observable<any> {
    return this._http.delete<any>(`${this._url}/customer/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: this._token,
      },
    });
  }

  getById(id: string): Observable<any> {
    return this._http.get(`${this._url}/customer/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: this._token,
      },
    });
  }

  updateCustomer(id: string, data: any): Observable<any> {
    return this._http.put(`${this._url}/customer/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: this._token,
      },
    });
  }

  importCustomers(formData: any, company_id: string): Observable<any> {
    return this._http.post<any>(
      `${this._url}/customer/import/${company_id}`,
      formData,
      {
        headers: {
          Authorization: this._token,
        },
      }
    );
  }

  getAllLinks(idUser: string): Observable<any> {
    return this._http.get(`${this._url}/link/${idUser}`, {
      headers: {
        Authorization: this._token,
      },
    });
  }

  createLink(body: any): Observable<any> {
    return this._http.post(`${this._url}/link`, body, {
      headers: {
        Authorization: this._token,
      },
    });
  }

  deleteLink(idCompany: string): Observable<any> {
    return this._http.delete(`${this._url}/link/${idCompany}`, {
      headers: {
        Authorization: this._token,
      },
    });
  }

  getlinkById(idLink: string): Observable<any> {
    return this._http.get(`${this._url}/link/details/${idLink}`, {
      headers: {
        Authorization: this._token,
      },
    });
  }

  updateLink(body: any): Observable<any> {
    return this._http.put(`${this._url}/link`, body, {
      headers: {
        Authorization: this._token,
      },
    });
  }
}
