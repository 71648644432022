import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { User } from 'app/auth/models';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {
  private _token: string;
  private _url = environment.apiUrl;
  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient) {
    this._token = `Bearer ${localStorage.getItem('currentToken')}`;
    
  }

  /**
   * Get all users
   */
  getAll() {
    return this._http.get<User[]>(`${this._url}/users`);
  }

  /**
   * Get user by id
   */
  getById(id: number) {
    return this._http.get<User>(`${this._url}/user/${id}`, {
      headers:{
        "Content-Type": "application/json",
        "Authorization": this._token
      }
    });
  }

  public activeAccount(email: string, signature: string): Observable<any> {
    return this._http.get(`${this._url}/user/verify/${email}?signature=${signature}`);
  }
}
