<div class="modal-content">
  <div class="modal-body">
    <div class="modal-header-left">
      <button type="button" class="btn btn-back" text-white (click)="goBack()">
        <span class="feather-icon" data-feather="arrow-left"></span>
        <span>Voltar</span>
      </button>
    </div>
    <div class="modal-title">
      <h1>Editar Link</h1>
    </div>
    <div class="mt-5" *ngIf="step === stepsEnum.DELETE">
      <h3>Tem Certeza que deseja excluir o link?</h3>
      <div class="d-flex justify-content-center mt-2">
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="deleteLink()"
        >
          Excluir Link
        </button>
        <button
          type="button"
          class="btn btn-primary btn-create ml-1"
          (click)="goBack()"
        >
          Voltar
        </button>
      </div>
    </div>
    <div class="mt-5" *ngIf="step === stepsEnum.SUCCESS">
      <h3>Excluído com sucesso</h3>
      <div class="d-flex justify-content-center mt-2">
        <button
          type="button"
          class="btn btn-primary btn-create ml-1"
          (click)="goBack()"
        >
          OK
        </button>
      </div>
    </div>
  </div>
</div>
