<div class="modal-content">
  <div class="modal-body">
    <div class="modal-header-left">
      <button type="button" class="btn btn-back" text-white (click)="dismiss()">
        <span class="feather-icon" data-feather="arrow-left"></span>
        <span>Voltar</span>
      </button>
    </div>
    <div class="modal-title">
      <h1>Link de Cadastro</h1>
    </div>
    <div class="user-links">
      <h3 *ngIf="countAvailableLinks > 0">
        {{ countAvailableLinks }}
        {{ countAvailableLinks > 1 ? "links disponíveis" : "link disponível" }}:
      </h3>
      <h3 *ngIf="countAvailableLinks === 0">
        Não há mais links disponíveis
      </h3>
      <ng-container *ngFor="let link of generatedLinks; let i = index">
        <div class="input-container">
          <div class="url-input">
            <span>{{ link.token }}</span>
          </div>

          <div class="icons-input">
            <button (click)="copyText(link)">
              <span class="icon" data-feather="copy"></span>
            </button>
            <button (click)="openModalEdit(link.id, link.token)">
              <span class="icon" data-feather="edit"></span>
            </button>
            <button (click)="openModalDeleteLink(link.id)">
              <span class="icon" data-feather="trash"></span>
            </button>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="action-content" [ngStyle]="{ 'padding-right': '26%' }">
      <button
       *ngIf="generatedLinks.length < 3"
        type="button"
        class="btn btn-primary btn-create"
        (click)="openModalCreateLink()"
      >
        Criar novo link de cadastro
      </button>
    </div>
  </div>
</div>
