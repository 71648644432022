export const locale = {
  lang: 'pt',
  data: {
    MENU: {
      CUSTOMER: 'Clientes',
      BUSINESS: 'Funil',
      REPORTS: 'Relatórios',
      CALENDAR: 'Calendário',
      DASHBOARD: {
        COLLAPSIBLE: 'Dashboards',
      },
    }
  }
};
