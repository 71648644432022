
import { CoreMenu } from '@core/types';

export const menuFinancial: CoreMenu[] = [
  {
    id: 'apps',
    type: 'section',
    title: 'Menu',
    icon: 'package',
    children: [      
      {
        id: "reports",
        title: "Relatórios",
        type: "item",
        icon: "pie-chart",
        url: "app/:company_id/reports",
      },
    ]
  }
];
