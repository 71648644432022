import { Injectable } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { Role, User } from "app/auth/models";
import { UserService } from "app/auth/service";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Observable } from "rxjs";
import { AuthenticationService } from "app/auth/service";

@Injectable({
  providedIn: "root",
})
export class AccessControlService {
  private _currentUser: User;
  private _isUserAdminSubject: BehaviorSubject<boolean>;
  public isUserAdmin$: Observable<boolean>;
  public authenticatedUser: any;

  constructor(
    private _userService: UserService,
    private _toastr: ToastrService,
    private _router: Router,
    private _authService: AuthenticationService
  ) {
    this._isUserAdminSubject = new BehaviorSubject<boolean>(false);
    this.isUserAdmin$ = this._isUserAdminSubject.asObservable();

    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.checkAccessControl(event.urlAfterRedirects);
      });

    this._authService.currentUser.subscribe((user) => {
      this.authenticatedUser = user;
    });
  }

  private checkAccessControl(url: string): void {
    this._currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (url.includes("/app")) {
      this._isUserAdminSubject.next(
        this._currentUser?.user_scope === Role.Admin ||
          this._currentUser?.user_scope === Role.Super_admin
      );
    }
  }
}
