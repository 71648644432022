import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataSharingRoleService {
  private roleSubject = new Subject<any>();

  sendDataRole(data: any) {
    this.roleSubject.next(data);
  }

  getDataRole() {
    return this.roleSubject.asObservable();
  }
}
