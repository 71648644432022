<div class="modal-content">
  <div class="modal-body">
    <div class="modal-header-left">
      <button type="button" class="btn btn-back" text-white (click)="goBack()">
        <span class="feather-icon" data-feather="arrow-left"></span>
        <span>Voltar</span>
      </button>
    </div>
    <div class="modal-title">
      <h1>Editar link</h1>
    </div>
    <div class="user-links">
      <div class="w-100 text-center">
        <h3>Campos disponíveis</h3>
      </div>
      <div class="input-container">
        <div class="url-input">
          <span>Nome</span>
        </div>
      </div>
      <div class="input-container">
        <div class="url-input">
          <span>E-mail</span>
        </div>
      </div>
      <div class="input-container">
        <div class="url-input">
          <span>Número</span>
        </div>
      </div>
      <ng-container *ngFor="let field of customFields; let i = index">
        <div class="input-container">
          <div class="dynamic-input">
            <span>{{ customFields[i] }}</span>
          </div>
          <div class="icons-input">
            <button (click)="removeField(i)">
              <span class="icon" data-feather="minus"></span>
            </button>
          </div>
        </div>
      </ng-container>
      <div class="input-container" *ngIf="showSelect">
        <select
          class="dynamic-input"
          name="custom-fields"
          #customField
          (change)="onSelected(customField.value)"
        >
          <option value="" selected>Selecione Campos Personalizados</option>
          <ng-container *ngFor="let field of customFieldsSelect; let i = index">
            <option
              *ngIf="!customFields.includes(field.name)"
              [value]="field.name"
            >
              {{ field.name }}
            </option>
          </ng-container>
        </select>
        <div class="icons-input">
          <button (click)="addField()">
            <span class="icon" data-feather="plus"></span>
          </button>
        </div>
      </div>
    </div>

    <div class="d-flex gap-1">
      <button type="button" class="btn btn-outline-primary" (click)="goBack()">
        Cancelar
      </button>

      <button
        type="button"
        class="ml-1 btn btn-primary btn-create"
        (click)="sendConfirm()"
      >
        Confirmar
      </button>
    </div>
  </div>
</div>
