import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "environments/environment";
import { User, Role } from "app/auth/models";
import { ToastrService } from "ngx-toastr";
import { DataSharingRoleService } from "./share-role.service";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  public currentUser: Observable<User>;

  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService,
    private _dataSharingRoleService: DataSharingRoleService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  get isAdmin() {
    return (
      this.currentUser &&
      (this.currentUserSubject.value.role === Role.Admin ||
        this.currentUserSubject.value.role === Role.Super_admin)
    );
  }

  get isClient() {
    return (
      this.currentUser && this.currentUserSubject.value.role === Role.Manager
    );
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {
    return this._http
      .post<any>(`${environment.apiUrl}/auth`, { email, password })
      .pipe(
        map((data) => {
          if (data && data.body.token) {
            localStorage.setItem("@userId", data.body.user.id);
            localStorage.setItem("currentUser", JSON.stringify(data.body.user));
            localStorage.setItem("currentToken", data.body.token);

            this._dataSharingRoleService.sendDataRole(true);

            setTimeout(() => {
              this._toastrService.success(
                "Login realizado com sucesso!" +
                  " Agora você pode começar a sua jornada. Let's go! 🎉",
                "👋 Bem-vindo(a), " + data.body.user.first_name + "!",
                { closeButton: true }
              );
            }, 2500);

            this.currentUserSubject.next(data.body.user);
          }

          return data.body.user;
        })
      );
  }

  /**
   * User logout
   *
   */
  logout() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("currentToken");
    localStorage.removeItem("@userId");

    this.currentUserSubject.next(null);
  }

  /**
   * Check if token is expired
   *
   */
  checkToken() {
    return this._http.get<any>(`${environment.apiUrl}/auth/check-token`);
  }
}
