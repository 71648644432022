export const customFieldsSelect = [
  {
    name: "Nascimento",
  },
  {
    name: "Setor",
  },
  {
    name: "Facebook",
  },
  {
    name: "Instagram",
  },
  {
    name: "X(Twitter)",
  },
  {
    name: "Linkedin",
  },
];
